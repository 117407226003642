@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body {
    font-family: 'Inter', sans-serif; font-size: 16px; line-height: 1; margin: 0; padding: 0; box-sizing: border-box; scroll-behavior: smooth;
}

// reset
* { margin: 0; padding: 0; }
h1, h2, h3, h4, h5, h6, p, span, input, textarea, select, button { margin: 0; padding: 0; font-family: 'Inter', sans-serif; }

button, a { cursor: pointer; }
// reset swiper
.services-next, .services-prev {
    &.swiper-button-disabled { opacity: 0.7 !important; }
 }

@media (max-width: 575px){
    html, body { font-size: 14px; }
}