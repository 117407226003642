.errorPage {
    margin-top: 4rem; margin-bottom: 4rem; display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 2rem;
    img { height: 22rem; }
    .title {
        h2 { font-size: var(--tx-sub-heading); font-weight: var(--fw-semibold); line-height: 1.3; text-align: center;}
        p { text-align: center; margin-top: 0.5rem; }
    }
    button {
        border:none; outline: none; background-color: var(--primary); color: var(--white) !important; padding: 0.5rem 1.5rem; border-radius: 5rem; height: 3rem; font-size: var(--tx-sub-title); font-weight: var(--fw-medium); transition: all 0.3s ease-in-out;
        &:hover { background-color: var(--primary-hover); }
    }
}

@media (max-width: 991px) {
    .errorPage {
        margin-top: 1.5rem; margin-bottom: 1.5rem;
        .title {  margin-bottom: 1rem; }
    }
}