.title {
    margin-bottom: 2rem;
    h2 { font-size: var(--tx-sub-heading); font-weight: var(--fw-semibold); line-height: 1.3; }
}
.postcontent {
    & > * { line-height: 1.6; }
    h2 { margin: 1.325rem 0rem; }
    h3 { margin: 1rem 0rem 0.325rem; }
    ul { margin: 0.5rem 0; padding-left: 1.25rem; }
    ol { margin: 0.5rem 0; padding-left: 1.25rem; }
    a {
        color: #3498db;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
    .faq {
        margin-top: 30px;
    }
    .faq h3 {
        margin-top: 15px;
    }
    .highlight { background-color: #eafaf1; padding: 10px; border-left: 4px solid #2ecc71; margin: 10px 0;
        li { line-height: 2; }
    }

    img {  display: block;
        max-width: 100%;  max-height: 750px; height: auto;  margin-top: 2rem; border: 1px solid var(--border-color); border-radius: 1rem; }
}