:root {
    --primary: #4C810A;
    --primary-hover: #3f6d07;
    --primary-light: #B9F548;
    --primary-light-hover: #a0dd2f;


    --white: #FFFFFF;
    --off-white: #F4F7F2;
    --border-color: #E1E4E0;

    --black: #0F1503;
    --black-500: #7E7F7D;

    --tx-heading: 4.5rem;
    --tx-sub-heading: 2.5rem;
    --tx-title: 1.75rem;
    --tx-sub-title: 1.125rem;
    --tx-body: 1rem;
    --tx-caption: 0.875rem;
    --tx-sub-caption: 0.725rem;

    --fw-normal: 400;
    --fw-medium: 500;
    --fw-semibold: 600;
    --fw-bold: 700;
}