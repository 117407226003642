.header {
   margin-top: 1rem; margin-bottom: 1rem;
    nav {
        display: flex; align-items: center; background-color: var(--white); border: 1px solid var(--border-color); border-radius: 20rem; padding: 0.75rem 1rem; box-shadow: 0 0 12px rgba($color: #000000, $alpha: 0.1);
        .logo {
            display: flex; align-items: center; gap: 0.75rem;
            .menuBtn { display: none;
                img { width: 1.5rem; align-items: center; justify-content: center; text-decoration: none; }
            }
        }
        .menu {
            margin: auto;
            ul { list-style: none; display: flex; align-items: center; gap: 1.5rem; padding: 0;
                a { color: var(--black); text-decoration: none; transition: all 0.3s ease;
                    &:hover { color: var(--primary); }
                }
            }
        }
        .getInTouch {           
            .getInTouchBtn {
                border: none; outline: none; background-color: var(--primary-light); padding: 0.5rem 1rem; border-radius: 5rem; height: 3rem; font-size: var(--tx-caption); font-weight: var(--fw-medium); cursor: pointer; transition: all 0.3s ease;
                &:hover { background-color: var(--primary-light-hover); }
            }
        }
    }
}


@media (max-width: 991px){
    .header nav {
        .logo {
            img { height: 2rem; }
        }

        .getInTouch  { margin-left: auto;
            .getInTouchBtn { height: 2.25rem; min-width: max-content; }
        }
        .menu ul { gap: 0.75rem;
            a { font-size: var(--tx-caption); }
        }
    }
}
@media (max-width: 768px){
    .header {
        margin: 0; padding: 0 !important; position: sticky; top: 0; height: 3.8125rem; z-index: 10;
        nav {
            border-radius: 0; box-shadow: none; border: 0; padding: 0.75rem 0.875rem; border-bottom: 1px solid var(--border-color); background-color: rgba($color: #FFFFFF, $alpha: 0.8); backdrop-filter: blur(5px); 
            .logo {
                img { height: 2rem; }
                // .menuBtn { display: flex; }
            }
            .menu {
                position: fixed; top: 3.8125rem; left: 0; bottom: 0; background-color: rgba($color: #FFFFFF, $alpha: 0.8); width: 100vw; backdrop-filter: blur(5px); z-index: 99;
                ul { flex-direction: column; padding: 1rem; gap: 0;
                    a { padding: 0.75rem 0; display: block; font-size: var(--tx-sub-title); }
                }

                &.show { animation: showMenu 0.3s ease forwards; }
                &.hide { animation: hideMenu 0.3s ease forwards; }
            }

            .getInTouch  { margin-left: auto;
                .getInTouchBtn { height: 2.25rem; }
            }
        }
    }
}

@keyframes showMenu {
    from {
      opacity: 0;
      transform: translateY(1rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}
  
@keyframes hideMenu {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(1rem);
    }
}

