$section-gap: 4rem;
$title-margin: 2rem;

@mixin card() {
    border: 1px solid var(--border-color); border-radius: 0.625rem; padding: 1.125rem; background-color: var(--white); display: flex; flex-direction: column;
}

@mixin section() {
    margin-top: $section-gap; margin-bottom: $section-gap;
    .title {
        margin-bottom: $title-margin;
        h2 { font-size: var(--tx-sub-heading); font-weight: var(--fw-semibold); line-height: 1.3; }
    }

    @media (max-width: 991px) {
        $section-gap: 1.5rem;
        $title-margin: 1rem;
        margin-top: $section-gap; margin-bottom: $section-gap;
        .title {
            margin-bottom: $title-margin;
            h2 { font-size: var(--tx-title);  }
        }
    }
}

.plans {    
    margin-top: $section-gap; margin-bottom: $section-gap; 
    .title {
        display: flex; flex-direction: column; align-items: center;  margin-bottom: $title-margin;
        h1 { font-size: var(--tx-heading); text-align: center; margin-bottom: 1.25rem; }
        p { font-size: var(--tx-sub-title); text-align: center; color: var(--black-500); max-width: 82ch; line-height: 1.5; }
    }
    @include section();
    .cards {
        display: grid; grid-template-columns: repeat(3, 1fr); gap: 1.5rem;

        .card {
           @include card(); margin-top: 2rem;

            .planTitle {
                display: flex; justify-content: space-between; gap: 1rem;
                h3 { margin-bottom: 0.75rem; width: 8ch; font-weight: var(--fw-semibold); line-height: 1.3; }
            }
            .price {
                margin: 3rem 0 0; display: flex; align-items: end; gap: 0.5rem;
                h2 { font-size: var(--tx-sub-heading); font-weight: 800; }
                span { font-size: var(--tx-caption); color: var(--black-500); margin-bottom: 0.5rem; }
            }
            .divider { border: 1px dashed var(--border-color); margin: 1.5rem 0; }
            .tag {
                font-size: var(--tx-caption); background-color: #FF5739; padding: 0.425rem 0.725rem; border-radius: 5rem; color: var(--white); display: flex; align-items: center; justify-content: center; max-height: 1.5rem
            }
            .features {
                ul { list-style: none; padding: 0; display: flex; flex-direction: column; gap: 1rem; margin-top: 1rem;
                    li { display: flex; align-items: center; gap: 0.75rem; font-weight: var(--fw-normal);
                        p { line-height: 1.3; }
                    }
                }
            }

            .actionBtn {
                margin-top: auto;
                button {
                    width: 100%; border: 1px solid var(--border-color); outline: none; background-color: var(--white); color: var(--black) !important; padding: 0.5rem 1rem; border-radius: 5rem; height: 3rem; font-size: var(--tx-sub-title); font-weight: var(--fw-medium); margin-top: 1.5rem; transition: all 0.3s ease-in-out;
                    &:hover { background-color: var(--off-white); }
                }
            }

            &.active {
                background: linear-gradient(349.16deg, #193003 48.18%, #2A4D05 101.23%); margin-top: 0;
                & > * { color: var(--white); }

                .planTitle {
                    p { color: #B6C1AB !important }
                }
                .price {
                    span { color: #B6C1AB }
                }
                .divider { border: 1px dashed #5C6553; }
                button { background-color: var(--primary-light); border: 0;
                    &:hover { background-color: var(--primary-light-hover); }
                }
            }
        }
    }
}

.services {
    @include section();
    .cards {
        .slide { height: auto; display: flex; }
        .card {
            @include card(); height: auto; background-color: var(--off-white);
            img { max-width: 3rem; margin-bottom: 2.5rem; }
            h5 { font-size: var(--tx-sub-title);  margin-bottom: 0.75rem; line-height: 1.5; }
            p { color: var(--black-500); line-height: 1.5; }
        }
        .navigation {
            display: flex; align-items: center; justify-content: center; gap: 1rem; margin: 2rem auto 0;
            a { width: 2.5rem; height: 2.5rem; aspect-ratio: 1/1; border-radius: 100%; background-color: var(--off-white); border: 1px solid var(--border-color); display: flex; align-items: center; justify-content: center; }
            .swiper-button-disabled { opacity: 0.5 !important; }
        }
    }
}

.whyChooseUs {
    @include section(); background: linear-gradient(180deg, var(--white) 50%, var(--off-white) 50%); padding-bottom: $title-margin;
    .cards {
        display: grid; grid-template-columns: repeat(3, 1fr); gap: 1.5rem;
        .card {
            @include card(); background-color: var(--white);
            .icon {
                width: 2.5rem; height: 2.5rem; aspect-ratio: 1/1; border-radius: 100%; background-color: var(--off-white); display: flex; align-items: center; justify-content: center; margin-bottom: 1.5rem;
            }
            h5 { font-size: var(--tx-sub-title);  margin-bottom: 0.75rem; }
            p { color: var(--black-500); line-height: 1.5; }
        }
    }
}

.projects {
    @include section();
    .cards {
        display: grid; grid-template-columns: repeat(2, 1fr); gap: 1.5rem;
        .card {
            border-radius: 0.625rem; overflow: hidden; position: relative; cursor: pointer; border: 1px solid var(--border-color);
            img { width: 100%; height: 100%; object-fit: cover; transform: scale(1.01); transition: all 0.3s ease-in-out; }
            
            &::after {
                content: ""; position: absolute; inset: 0; z-index: 1; background-color: rgba(0, 0, 0, 0.6); opacity: 0; visibility: hidden; transition: all 0.3s ease-in-out;
            }
            p { font-size: var(--tx-title); font-weight: var(--fw-semibold); width: max-content; color: var(--white); position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 2; opacity: 0; visibility: hidden; transition: all 0.3s ease-in-out; }

            &:hover {
                img { transform: scale(1.05); }
                &::after { opacity: 1; visibility: visible; }
                p { opacity: 1; visibility: visible; }
            }
            
        }
    }
    .actionBtn {
        margin-top: auto; display: flex; align-items: center; justify-content: center;
        button {
            border:none; outline: none; background-color: var(--primary); color: var(--white) !important; padding: 0.5rem 1.5rem; border-radius: 5rem; height: 3rem; font-size: var(--tx-sub-title); font-weight: var(--fw-medium); margin: 2rem 0; transition: all 0.3s ease-in-out;
            &:hover { background-color: var(--primary-hover); }
        }
    }
}

.clientsTestimonials {
    @include section();

    .cards {
        .card {
            @include card(); padding: 0.625rem;
            .content { background-color: var(--off-white); border-radius: 0.5rem; padding: 1.5rem; }
            .stars { display: flex; align-items: center; gap: 0.325rem; margin-bottom: 1rem; }
            .description { margin-bottom: 1rem; color: var(--black-500); line-height: 1.5; }

            .clientInfo {
                display: flex; align-items: center; justify-content: space-between; gap: 1rem; position: relative;
                .client { display: flex; align-items: center; gap: 0.75rem;
                    p { margin-bottom: 0.25rem; }
                }
                .quote { opacity: 0.1; }
            }
        }
        .navigation {
            display: flex; align-items: center; justify-content: center; gap: 1rem; margin: 2rem auto 0;
            a { width: 2.5rem; height: 2.5rem; aspect-ratio: 1/1; border-radius: 100%; background-color: var(--off-white); border: 1px solid var(--border-color); display: flex; align-items: center; justify-content: center; }
            .swiper-button-disabled { opacity: 0.5 !important; }
        }
    }
}

.frequentlyAskedQuestions {
    @include section(); display: grid; grid-template-columns: repeat(3, 1fr); gap: 1.5rem;
    .cards { 
       grid-column: 2 / span 2; display: flex; flex-direction: column; gap: 1.5rem;
    }
}

@media (max-width: 991px) {
    $section-gap: 1.5rem;
    $title-margin: 1rem;

    .plans {
        margin-top: $section-gap; margin-bottom: $section-gap;
        .title {
            margin-bottom: $title-margin;
            h1 { font-size: var(--tx-sub-heading); text-align: center; margin-bottom: 1rem; }
            p { font-size: var(--tx-caption); }
        }  
        .cards {
            grid-template-columns: repeat(2, 1fr);
            .card { margin-top: 0;
            }
        }
    }

    .whyChooseUs .cards { grid-template-columns: repeat(1, 1fr); gap: 1rem; }
    .projects {
        .cards { gap: 1rem; }
        .actionBtn button { margin: 1rem 0; }
    }

    .frequentlyAskedQuestions {
       grid-template-columns: repeat(1, 1fr); gap: 1rem;
        .cards { 
           grid-column: 1 / span 1; gap: 1rem;
        }
    }
}

@media (max-width: 767px) {
    .services, .clientsTestimonials {
        .cards .navigation { display: none; }
    }
}

@media (max-width: 575px) {
    .plans{
        .cards { grid-template-columns: repeat(1, 1fr); }
    }
    .projects {
        .cards {
           grid-template-columns: repeat(1, 1fr);
        }
    }
}