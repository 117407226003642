.accordion {
    border: 1px solid var(--border-color); border-radius: 0.625rem; overflow: hidden; background-color: transparent;

    .accordionHeader {
        padding: 1rem; font-size: var(--tx-body); font-weight: var(--fw-semibold); line-height: 1.5; text-align: left; background: var(--white); border: none; outline: none; cursor: pointer; display: flex; justify-content: space-between; align-items: center; gap: 1rem;
    
        .accordionIcon { width: 1.325rem; height: 1.325rem; min-width: 1.325rem; min-height: 1.325rem; aspect-ratio: 1 / 1; position: relative; border-radius: 50%; border: 2px solid var(--black);
            &::before { content: ''; position: absolute; top: 50%; left: 50%; width: 0.625rem; height: 2px; background-color: var(--black); border-radius: 0.25rem; transform: translate(-50%, -50%) rotate(0deg); transform-origin: center; transition: transform 0.3s ease, opacity 0.3s ease; }     
            &::after { content: ''; position: absolute; top: 50%; left: 50%; height: 0.625rem; width: 2px; background-color: var(--black); border-radius: 0.25rem; transform: translate(-50%, -50%) rotate(90deg); }
        } 

        &.show { background-color: var(--off-white);
            .accordionIcon::before { transform: translate(-50%, -50%) rotate(90deg); } 
        }
    }
    .accordionContent {
        padding: 1rem;
        & { line-height: 1.5; }
    }
}
  
  
