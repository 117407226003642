/* Base Styles */
.getInTouch {
    max-width: 40rem; margin: 4rem auto; padding: 1.5rem; background-color: var(--white); border-radius: 0.625rem; border: 1px solid var(--border-color);

    .formHeader { margin-bottom: 2rem;
        h1 { font-size: var(--tx-sub-heading); }
        p { color: var(--black-500); margin-top: 0.5rem; }       
    }

    .form {
        display: flex; flex-direction: column; gap: 1.5rem;
        .formControl { display: flex; flex-direction: column; gap: 0.5rem; position: relative;
            label { font-weight: var(--fw-semibold); font-size: var(--tx-caption); }
            input, select, textarea { border: 1px solid var(--border-color); border-radius: 0.625rem; padding: 0.75rem; outline: none; font-size: var(--tx-caption);
                &::placeholder { color: var(--black-500); }
                &:focus, &:focus-visible { border-color: var(--black-500); }
            }

            select {
                appearance: none; background-color: #fff; background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>'); background-repeat: no-repeat; background-position: right 0.725rem center; background-size: 1.325rem 1.325rem;
            }
        }
        .submitBtn {
            border: none; outline: none; background-color: var(--primary); padding: 0.5rem 1rem; border-radius: 5rem; height: 3rem; font-size: var(--tx-body); font-weight: var(--fw-medium); color: var(--white); cursor: pointer; transition: all 0.3s ease;
            &:hover { background-color: var(--primary-hover); }
        }
        .req::after { content: "*"; color: #d9534f; margin-left: 0.25rem; }
    }
}

/* Error Styling */
.errorDetails {
    color: #d9534f; font-size: var(--tx-sub-caption); position: absolute; bottom: -1rem;
}
.error { border-color: #d9534f; }

.popup {
    position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); border-radius: 10px; padding: 20px; text-align: center; z-index: 1000;
    .closeButton { background: none; border: none; font-size: 1.5rem; width: 1.875rem; height: 1.875rem; border: 1px solid var(--border-color); border-radius: 50%; cursor: pointer; display: flex; align-items: center; justify-content: center; position: absolute; top: 10px; right: 10px; }
    p { font-size: var(--tx-body); margin-bottom: 1rem; }
  }
  
.overlay { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); z-index: 999; }

.checkMarkCircle{
    stroke-dasharray: 166;stroke-dashoffset: 166;stroke-width: 2;stroke-miterlimit: 10;stroke: #7ac142;fill: none;animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}
.checkMark{
    width: 56px;height: 56px;border-radius: 50%;display: block;stroke-width: 2;stroke: #fff;stroke-miterlimit: 10;margin: 10% auto;box-shadow: inset 0px 0px 0px #7ac142;animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}
.checkMarkCheck{
    transform-origin: 50% 50%;stroke-dasharray: 48;stroke-dashoffset: 48;animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}
  @keyframes stroke{
    100%{stroke-dashoffset: 0}
  }
  @keyframes scale{
    0%, 100%{transform: none}
    50%{transform: scale3d(1.1, 1.1, 1)}
  }
  @keyframes fill{
    100%{box-shadow: inset 0px 0px 0px 30px #7ac142}
  }

  
  

@media (max-width: 767px){
    .getInTouch { margin: 3rem 0.875rem; padding: 1rem; }
}
