.blogs {
    margin-top: 4rem; margin-bottom: 4rem;
    .title {
        margin-bottom: 2rem;
        h2 { font-size: var(--tx-sub-heading); font-weight: var(--fw-semibold); line-height: 1.3; }
    }
    .cards {
        display: flex; flex-wrap: wrap; gap: 1.5rem;
        .card {            
            .image {
                border-radius: 0.625rem;
                overflow: hidden;
                border: 1px solid var(--border-color);
                height: 200px;
                width:420px;
                display: flex;
                align-items: center;
                justify-content: center;
            
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; 
                }
            }           
            
            a { font-size: var(--tx-sub-title); font-weight: var(--fw-semibold); width: max-content; display: inline-block;
                &:hover { text-decoration: none; }
            }
            span { font-size: var(--tx-caption); color: var(--black-500); margin-top: 0.325rem; display: block; }            
        }
    }
}

@media (max-width: 991px) {
    .blogs {
        margin-top: 1.5rem; margin-bottom: 1.5rem;
        .title {  margin-bottom: 1rem; }
        .cards { grid-template-columns: repeat(2, 1fr); gap: 1rem; }
    }
}

@media (max-width: 575px) {
    .blogs {
        .cards { grid-template-columns: repeat(1, 1fr); gap: 1rem; }
    }
}