.footer {
    background: linear-gradient(344.79deg, #193003 61.53%, #2A4D05 115.14%);
    
    .footerContent {
        padding: 3rem 0;
        .logo { display: flex; align-items: center; justify-content: center;
            img { filter: invert(1); }
        }
        ul { list-style: none; display: flex; align-items: center; justify-content: center; gap: 1.5rem; margin-top: 1.5rem;
            li a { text-decoration: none; color: var(--white); }
        }
    }
    .socialMedia {
        display: flex;
        gap: 15px;
        margin-top: 30px;
        justify-content: center;
        align-items: center;

        a{
            img{
                height: 1.5rem;
                width: 1.5rem;
                margin: 0rem;
            }
        }
    }
    .copyRight {
        border-top: 1px solid #4E6538; padding: 1rem 0; text-align: center;
        p { color: var(--white); font-size: var(--tx-caption); }
    }
}

@media (max-width: 991px) {
    .footer {
        .footerContent {
            ul { flex-direction: column;
                li { border-bottom: 1px solid #4E6538; flex-grow: 1; }
            }
        }
    }
}