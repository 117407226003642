// Font sizes    
$font-sizes: (
    "heading": var(--tx-heading), "sub-heading": var(--tx-sub-heading), "title": var(--tx-title), "sub-title": var(--tx-sub-title), "body": var(--tx-body), "caption": var(--tx-caption), "sub-caption": var(--tx-sub-caption)
);
@each $name, $value in $font-sizes {
    .tx-#{$name} { font-size: $value !important; };
}

// Font weights  
$font-weights: (
    "normal": var(--fw-normal), "medium": var(--fw-medium), "semi-bold": var(--fw-semibold), "bold": var(--fw-bold)
); 
@each $name, $value in $font-weights {
    .fw-#{$name} { font-weight: $value !important; };
}

// Colors  
$colors: (
    "primary": var(--primary), "primary-light": var(--primary-light), "white": var(--white), "off-white": var(--off-white), "black": var(--black), "black-500": var(--black-500)
);
@each $name, $value in $colors {
    .text-#{$name} { color: $value !important; };
}

// layout

.container-xl { max-width: 1600px; margin-left: auto; margin-right: auto; padding-left: 1rem; padding-right: 1rem; }
.container { max-width: 1320px; margin-left: auto; margin-right: auto; padding-left: 1rem; padding-right: 1rem; }

@media (max-width: 576px) {
    .container { padding-left: 0.875rem; padding-right: 0.875rem; }
}